@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');
@import url('https://fonts.googleapis.com/css2?family=Bebas+Neue&family=Manrope:wght@300;400;500;600;700&family=Poppins:wght@300;400;500;600;700;800;900&display=swap');

*{
    padding: 0;
    margin: 0;
}
html, body {
    overflow-x: hidden;
    width: 100%;
}
h1, h2, h3, h4, h5, h6, body, p, ul, li {
    padding: 0;
    margin: 0;
    font-family: 'Poppins', sans-serif;
}
ul, li {
    padding: 0 !important;
    margin: 0 !important;
}
a{
    text-decoration: none !important;
}
.center {
    text-align: center;
}
.uppercase {
    text-transform: uppercase;
}
.bg-orange {
    background: #FFA800;
}
.orange {
    color: #FFA800;
}
/* Home page Styling Start Here */
.header {
    background: rgba(56, 66, 96, 0.4);
    position: fixed;
    width: 100vw;
    z-index: 1;
}
.navbar {
    height: 90px;
    background: transparent;
}
.navbar-brand img {
    width: 216px;
}
.navbar-nav li a {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    letter-spacing: 0.1em;
    color: #FFFFFF;
    padding: 10px !important;
    margin-right: 15px;
}
.navbar-nav li a:hover, .navbar-nav li .active {
    color: #FFA800 !important;
}
.btn-nav {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 95px;
    height: 44px;
    background: #FFA800;
    border-radius: 5px;
    border: 1px solid #FFA800;
    color: #fff;
}
.btn-nav-login {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 10px;
    width: 95px;
    height: 44px;
    background: transparent;
    border-radius: 5px;
    border: 1px solid #fff;
    color: #fff;
    margin-right: 10px;
}
/* Banner Styling Start Here */
.banner-content {
    height: 800px;
    background-size: cover;
    background-repeat: no-repeat;
    opacity: 0.9;
}
.banner-content h2 {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 150px;
    line-height: 180px;
    text-align: center;
    color: #FFFFFF;
    width: 890px;
    margin: 0 auto;
    padding-top: 150px;
}
.sub-nav-banner {
    margin: 30px auto 30px auto !important;
    list-style-type: none;
    justify-content: center;
    padding: 0px;
}


.btn-nav-drop {
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    padding: 0px;
    width: 100%;
    height: 44px;
    border-radius: 0px;
    color: #fff;
    margin-top: 3px;
}
.btn-nav-drop:hover{
    background: rgba(255,255,255, 0.3);
    transition: 0.2s;
}
.dropdown-menu[data-bs-popper]{
    background: #FFA800;
    border-radius: 0px;
}
.pull-left{
    color: #fff;
}

.pull-left:hover {
    background: #FFA800;
    color: #fff;
    transition: 0.2s;
}
.sub-nav-banner .dropdown-toggle::after {
    display: none;
}
.thumbnail-image {
    width: 11.17px;
    height: 6.59px;
    margin-left: 11px;
}
.search-banner {
    position: relative;
    width: 756px;
    margin: 0 auto;
}
.search-banner input {
    width: 756px;
    height: 60px;
    background: #FFFFFF;
    border: 2px solid #EBEBEB;
    border-radius: 100px;
    padding-left: 30px;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 30px;
    color: #384260;
}
.search-banner input:focus {
    color: unset;
    border-color: #EBEBEB;
    outline: unset;
    box-shadow: unset;
}
.search-banner button {
    background: #FFA800;
    border: 2px solid #EBEBEB;
    border-radius: 0px 100px 100px 0px;
    width: 76px;
    height: 60px;
    position: absolute;
    right: 0;
}
.search-banner button img {
    width: 18px;
    height: 18px;
}
/* Category Styling Start Here */
.category {
    padding-bottom: 150px;
}
.category-heading-text {
    position: relative;
    font-style: normal;
    font-weight: 600;
    font-size: 42px;
    line-height: 63px;
    color: #000000;
    width: 481px;
    margin: 96px auto 66px auto;
}
.yellow-line {
    position: absolute;
    width: 268px;
    right: -10px;
    bottom: -18px;
}
.cat-box {
    border: 1.5px solid rgba(0, 0, 0, 0.54);
    border-radius: 10px;
    text-align: center;
    padding: 60px 0;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    height: 208px;
    margin-bottom: 20px;
    
}

.cat-box h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 18px;
    line-height: 24px;
    color: #2D2E2F;
    text-transform: capitalize;
    margin-top: 10px;
    flex-basis: 100%;
}
/* Footer Styling Start Here */
.footer-content {
    padding: 47px 0 55px 0;
    background: #384260;
    color: #fff;
}
.comm-footer-navs h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    margin-bottom: 16px;
}
.comm-footer-navs ul {
    list-style-type: none;
}
.comm-footer-navs ul li a {
    margin-bottom: 8px;
    display: inline-block;
    color: #fff;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.brand-footer img {
    width: 216px;
}
.comm-footer-navs p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
    text-shadow: 0px 0.5px 4px rgb(0 0 0 / 7%);
    margin: 20px 0 40px 0;
}
.lower-footer {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 62px;
    font-style: poppins;
    font-weight: 400;
    position: absolute;
    line-height: 21px;
    font-size: 14px;
    background-color: #FFA800
}
/* ================= Listing Page Styling ================= */
.p-t-90 {
    padding-top: 90px;
}
.bg-dark-blue {
    background-color: #384260;
}
.dark-blue {
    color: #384260;
}
.tag-search-content {
    padding: 50px 85px 30px 85px;
    max-width: 1440px;
    margin: 0 auto;
    border-bottom: 1px solid #EBEBEB;
}
.tags-content {
    margin-top: 20px;
    flex-wrap: wrap;
    row-gap: 10px;
}
.tags-content a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    text-transform: capitalize;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 8px 16px;
    color: #717171;
    border: 2px solid #EBEBEB;
    border-radius: 20px;
    margin-right: 12px;
    transition: 0.3s;
    flex: 0 0 auto;
}
.tags-content a:hover {
    
    color: #fff;
    transition: 0.3s;
    background: #FFA800;
}
.listing-content {
    max-width: 1440px;
    margin: 0 auto;
}
.filters-listing {
    margin: 40px 0 0px 0;
}
.filters-listing p {
    padding: 0 0 0 135px;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    color: #000000;
    display: flex;
    align-items: center;
}
.filters-listing button {
    display: flex;
    justify-content: center;
    align-items: center;
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 18px;
    padding: 12px 36px 10px 36px;
    height: 42px;
    background: #FFA800;
    border-radius: 100px;
    border: 1px solid #FFA800;
    color: #fff;
}
.filters-listing img {
    margin-right: 10px;
}
.list-box {
    padding: 0 0 45px 100px;
    border-bottom: 1px solid #EBEBEB;
    margin-top: 30px;
}
.list-box-thumb {
    margin-right: 24px;
}
.list-box-thumb img {
    width: 150px;
}
.title-review-status h5 {
    font-style: normal;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
    color: #2D2E2F;
}
.title-address p {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 16px;
    text-align: right;
    color: #6E7072;
    width: 115px;
}
.review-total p {
    width: 100%;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    text-align: center;
    display: flex;
    align-items: center;
    color: #172234;
    margin: 6px 0 10px 0px;
}
.review-total p img {
    margin-right: 5px;
    position: relative;
    top: -1.5px;
}
.review-total p span {
    color: #717171;
}
.review-total label {
    background: #F0F0F0;
    border-radius: 4px;
    padding: 3px 5px;
    font-weight: 400;
    font-size: 12px;
    line-height: 12px;
    color: #6E7072;
}
.service-box {
    margin-right: 24px;
}
.service-box img {
    width: 16px;
    height: 16px;
}
.service-box p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 15px;
    color: #6E7072;
    margin-left: 5px;
    transition: 0.3s;
    cursor: pointer;
    margin: 0 0 0 10px
}
.service-box p:hover {
    transition: 0.3s;
    color: #0070CC;
}
.list-info {
    padding: 10px 0 15px 0;
    border-bottom: 1px solid #EBEBEB;
}
.list-info p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #6E7072;
    padding-left: 5px;
}
.respond-request {
    margin-top: 15px;
}
.respond-text p {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #2D2E2F;
    margin-bottom: 2px;
}
.respond-text span {
    font-style: normal;
    font-weight: 400;
    font-size: 11px;
    line-height: 16px;
    color: #6E7072;
}
.respond-text p span {
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
    color: #008055;
    margin-left: 7px;
}
.respond-request button {
    background: #FFFFFF;
    border: 1px solid #C8C9CA;
    border-radius: 4px;
    font-style: normal;
    font-weight: 400;
    font-size: 15px;
    line-height: 24px;
    text-align: center;
    letter-spacing: 0.2px;
    color: #2D2E2F;
    padding: 4px 17px;
    height: 34px;
}
/* Theme Pagination */
.theme-pagination {
    margin: 30px 0 30px 0;
}
.theme-pagination ul {
    display: flex;
    list-style-type: none;
    justify-content: center;
}
.theme-pagination ul li a {
    width: 32px;
    height: 32px;
    background: transparent;
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    color: #172234; 
}
.theme-pagination ul li a:hover {
    background: #172234;
    color: #fff;
    transition: 0.3s;
}
.active {
    background: #172234 !important;
    color: #fff !important;
}
.theme-pagination ul li:first-child a:hover {
    background: transparent;
    color: #172234; 
    transition: 0.3s;
}
.theme-pagination ul li:last-child a:hover {
    background: transparent;
    color: #172234; 
    transition: 0.3s;
}
/* Theme Pagination */
.dummy-map img {
    width: 100%;
    height: auto;
}

/* ================= About US Styling ================= */
.about-banner-content {
    height: 590px;
}
.about-banner-content h3 {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 65px;
    line-height: 65px;
    text-align: center;
    margin: 0 auto;
    padding-top: 150px;
}
.about-banner-content p {
    font-family: 'Bebas Neue';
    font-style: normal;
    font-weight: 700;
    font-size: 35px;
    line-height: 35px;
    text-align: center;
    color: #FFFFFF;
    margin-top: 35px;
}
.about-details {
    padding: 70px 0;
}
.about-text {
    width: 670px;
    margin: 0 auto;
}
.about-text h2 {
    font-style: normal;
    font-weight: 400;
    font-size: 43px;
    line-height: 44px;
    color: #222222;
}
.about-text p {
    font-style: normal;
    font-weight: 400;
    font-size: 18px;
    line-height: 22px;
    color: rgba(23, 34, 52, 0.6);
    margin-top: 15px;
}
.about-facts {
    padding: 100px 0;
    background: #F2F2F2;
}
.about-fact-content h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 27.0698px;
    line-height: 37px;
    color: #222222;
    margin-bottom: 25px;
}
.fact-box {
    background: #fff;
    border-radius: 10px;
    padding: 30px;
}
.fact-box h4 {
    font-style: normal;
    font-weight: 400;
    font-size: 54.1395px;
    line-height: 61px;
    color: #222222;
    text-transform: uppercase;
}
.fact-box p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 15.2267px;
    line-height: 20px;
    color: #222222;
    margin-top: 10px;
}
.fact-box span {
    font-style: normal;
    font-weight: 400;
    font-size: 9.30523px;
    line-height: 14px;
    color: #717171;
    margin-top: 45px;
}
.about-founders {
    border-bottom: 1px solid #EBEBEB;
    padding: 80px 0 20px 0;
}
.about-founder-content h3 {
    font-style: normal;
    font-weight: 400;
    font-size: 43px;
    line-height: 44px;
    color: #222222;
    margin-bottom: 20px;
}
.founder-box img {
    width: 100%;
    border-radius: 10px;
    margin-bottom: 10px;
}
.founder-box h4 {
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #000000;
}
.founder-box p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #717171;
}
/* Login Page Styling */
.login-content {
    padding: 70px 0;
}
.login-box {
    padding-right: 40px;
}
.login-box h5 {
    font-style: normal;
    font-weight: 500;
    font-size: 32px;
    line-height: 28px;
}
.login-box p {
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 18px;
    margin: 20px 0;
}
.anch-color {
    color: #0073BB !important;
    text-decoration: underline !important;
    transition: 0.3s;
}
.anch-color:hover {
    color: #0073BB !important;
    text-decoration: underline !important;
    transition: 0.3s;
}
.login-box span {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
    margin-bottom: 20px;
}
.login-form .form-control {
    background: #FFFFFF;
    box-shadow: 0px 2px 40px rgb(23 34 52 / 5%);
    border-radius: 6px;
    border: unset;
    font-style: normal;
    font-weight: 400;
    font-size: 16px;
    line-height: 24px;
    color: #666666;
    height: 56px;
    padding-left: 15px;
}
.login-form a {
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 18px;
}
.login-form button {
    background: #FFA800;
    box-shadow: 0px 2px 40px rgb(23 34 52 / 5%);
    border-radius: 6px;
    border: unset;
    width: 100%;
    margin: 20px 0 12px 0;
    height: 56px;
    font-style: normal;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0;
}
.login-form button:hover, .login-form button:active, .login-form button:focus-visible {
    background: #FFA800 !important;
    box-shadow: 0px 2px 40px rgb(23 34 52 / 5%) !important;
    color: #fff;
}
.login-img {
    display: flex;
    align-items: center;
    justify-content: center;
}
.login-img img {
    width: 100%;
    height: auto;
}


/* Dashboard HomePage */


.dashboard-wrapper {
    width: 100%;
    height: 100vh;
    overflow: hidden;
    font-family: 'Manrope', sans-serif;
}
.dash-side-header {
    border-right: 0.5px solid rgba(117, 117, 117, 0.2);
    padding: 0 40px 0 15px;
    height: 100vh;
    max-width: 215px;
    min-width: 215px;
}
.dash-body {
    width: calc(100% - 215px);
    padding-left: 30px;
    overflow-y: scroll;
}
.dash-logo img {
    width: 174px;
    margin: 20px 0;
}
.nav-side-links {
    padding: 0;
    margin: 0;
    list-style-type: none;
}
.nav-side-links li a {
    font-family: 'Manrope', sans-serif;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #757575;
    height: 34px;
    margin-bottom: 15px;
    padding: 0 0 0 20px;
    transition: 0.3s;
}
.nav-side-links li a:hover {
    background: #ECF3FB;
    color: #2E5BFF;
    transition: 0.3s;
}
.nav-side-links li a img {
    width: 20px;
    height: 20px;
    margin-right: 20px;
}
.top-dash-header {
    border-bottom: 0.7px solid #E3E3E3;
    padding: 10px 0;
}
.dash-page-title img {
    width: 24px;
    height: 24px;
    margin-right: 15px;
}
.dash-page-title h4 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    text-transform: capitalize;
    color: #1E1E1E;
}
.signout-notify button {
    padding: 0;
    margin-left: 40px;
}
.signout-notify button:active, .signout-notify button.show {
    border-color: transparent !important;
}
.signout-notify button::after {
    display: none;
}
.notfication button img {
    width: 20px;
    height: 20px;
}
.signout-user {
    margin-right: 28px;
}
.signout-user button img {
    width: 48px;
    height: 48px;
    border-radius: 50%;
    margin-right: 10px;
}
.arrow-ico-grey {
    width: 12px !important;
    height: 12px !important;
    margin: 0 0 0 6px !important;
}
.total-over-add {
    padding: 30px 30px 30px 0;
    border-bottom: 0.7px solid #E3E3E3;
}
.total-over-add h5 {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 24px;
    line-height: 20px;
    text-transform: capitalize;
    color: #1E1E1E;
}
.total-over-add a {
    display: flex;
    align-items: center;
    background: #2E5BFF;
    border-radius: 8px;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 600;
    font-size: 16px;
    line-height: 22px;
    height: 42px;
    padding: 0 25px;
    color: #fff;
}
.total-over-add a img {
    width: 20px;
    height: 20px;
    margin-right: 10px;
}
.over-tbl-content {
    padding: 30px 30px 0 0;
}
.overview-box {
    border: 1px solid rgba(117, 117, 117, 0.2);
    border-radius: 12px;
    padding: 30px 35px;
}
.overview-box h6 {
    color: #5F5F62;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 20px;
    margin-bottom: 25px;
}
.overview-box p {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 40px;
    line-height: 55px;
    display: flex;
    align-items: center;
    text-transform: capitalize;
    color: #1E1E1E;
}
.overview-box p small {
    background: rgba(30, 147, 43, 0.1);
    border-radius: 100px;
    padding: 5px 15px;
    font-family: 'POPPINS';
    font-style: normal;
    font-weight: 600;
    font-size: 9px;
    line-height: 11px;
    color: #1E932B;
    position: relative;
    top: 6px;
}
.users-multi-img img {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    position: relative;
    left: -5px;
    border: 2px solid #fff;
}
.users-multi-img img:first-child {
    position: unset;
}
.user-table-wrapper {
    margin: 40px 0;
    overflow-y: scroll;
    border: 1px solid rgba(117, 117, 117, 0.2);
    border-radius: 12px;
}
.user-tbl thead tr {
    background: #EBF1FE;
}
.user-tbl thead th {
    white-space: nowrap;
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 500;
    font-size: 14px;
    line-height: 19px;
    color: #757575;
    padding: 20px;
}
.user-tbl tbody tr {
    border-bottom: 1px solid rgba(117, 117, 117, 0.2);
}
.user-tbl tbody tr:last-child {
    border-bottom: unset;
}
.user-tbl tbody td {
    padding: 15px 20px;
}
.company-cell img {
    max-width: 50px;
    max-height: 50px;
    margin-right: 10px;
}
.company-cell {
    white-space: nowrap;
    align-items: center;
}
.company-cell p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 500;
    font-size: 13.3139px;
    line-height: 22px;
    text-transform: capitalize;
    color: #191919;
}
.company-cell p span {
    color: #757575;
}
.customer-cell {
    font-family: 'Manrope';
    font-style: normal;
    font-weight: 700;
    font-size: 10px;
    line-height: 22px;
    display: flex;
    align-items: center;
    text-align: center;
    text-transform: capitalize;
    color: #53E663;
    background: #DCFFE0;
    border-radius: 100px;
    padding: 0 15px;
}
.about-cell p {
    font-family: 'Poppins';
    font-style: normal;
    font-weight: 400;
    font-size: 10px;
    line-height: 20px;
    color: #6E7072;
}
.search-baner{
  
    background: #FFA800;
    border: 2px solid #EBEBEB;
    border-radius: 0px 100px 100px 0px;
    width: 76px;
    height: 60px;
    position: absolute;
    right: 500px;
  }

.search {
    display: flex;
}
  